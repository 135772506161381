import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { HomeIcon, InfoIcon } from '@primer/octicons-react'

import Link from '@components/link'

const activeLinkClass = 'active'

const Aside = () => {
  const path = () => typeof window === 'object' ? window.location.pathname : null

  const [homeClass, setHomeClass] = useState('')
  const [aboutClass, setAboutClass] = useState('')
  useEffect(() => {
    setHomeClass((path() === '/' || path() === '') ? activeLinkClass : '')
    setAboutClass((path() || '').startsWith('/about') ? activeLinkClass : '')
  }, [])

  function handleToggle(evt) {
    if (evt.currentTarget && evt.currentTarget.name === 'toggle') {
      evt.preventDefault();

      if (document.body.classList.contains('offcanvas')) {
        evt.currentTarget.classList.remove('active')
        document.body.classList.remove('offcanvas')
      } else {
        evt.currentTarget.classList.add('active')
        document.body.classList.add('offcanvas')
      }
    } else {
      if (document.body.classList.contains('offcanvas')) {
        evt.currentTarget.classList.remove('active')
        document.body.classList.remove('offcanvas')
      }
    }
  }

  return (
    <React.Fragment>
      <a href="#" name="toggle" className="nav-toggle" onClick={handleToggle}><i></i></a>

      <aside id="aside" role="complementary" className="js-fullheight">
        <nav id="main-menu" role="navigation">
          <ul>
            <GlassList className={homeClass}>
              <HomeIcon size="small" aria-label="Home" />
              <NavLink to={ `/` } onClick={handleToggle}>Home</NavLink>
            </GlassList>
            <GlassList className={aboutClass}>
              <InfoIcon size="small" aria-label="About" />
              <NavLink to={ `/about` } onClick={handleToggle}>About</NavLink>
            </GlassList>
          </ul>
        </nav>

      </aside>
    </React.Fragment>
  )
}

const GlassList = styled.li`
  background: ${props => props.className === activeLinkClass ? 'rgba( 255, 255, 255, 0.50 )' : 'rgba( 255, 255, 255, 0.12 )' };
  box-shadow: 0 8px 10px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 26px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: #fff;
  max-width: 90%;
  margin: 0 auto 20px !important;
  padding: 10px !important;
`;

const NavLink = styled(Link)`
  color: #fff !important;
`;

export default Aside
